var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', {
    attrs: {
      "title": _vm.type ? '创建收款' : '创建退款',
      "visible": _vm.visible,
      "width": "500px",
      "before-close": _vm.handleClose,
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "x3 aic border-bottom"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v(" 诚意金"), _c('br'), _c('span', [_vm._v("最大可认购数、诚意金金额均有当前活动定义")])]), _vm.type ? _c('img', {
    attrs: {
      "src": require("@/assets/shou.png"),
      "alt": "",
      "width": "35px"
    }
  }) : _c('img', {
    attrs: {
      "src": require("@/assets/tui.png"),
      "alt": "",
      "width": "35px"
    }
  })]), _vm.type ? _c('div', [_c('ul', [_c('li', {
    staticClass: "x3"
  }, [_c('div', [_vm._v("可认购数量")]), _c('div', [_vm._v(_vm._s(_vm.params.appointmentCount) + "套")])]), _c('li', {
    staticClass: "x3 border-bottom"
  }, [_c('div', [_vm._v("应收诚意金")]), _c('div', [_vm._v("¥ " + _vm._s(_vm.params.appointmentAmount))])]), _c('li', {
    staticClass: "x3"
  }, [_c('div', [_vm._v("累计已收款")]), _c('div', [_vm._v("¥ " + _vm._s(_vm.params.payAmount))])]), _c('li', {
    staticClass: "x3 border-bottom"
  }, [_c('div', [_vm._v("本次应收款")]), _c('div', {
    staticClass: "red"
  }, [_vm._v("¥ " + _vm._s(_vm.params.appointmentAmount))])])]), _c('div', {
    staticClass: "tigs"
  }, [_vm._v("确认后不可修改，你确定要执行收款确认吗？")]), _c('el-input', {
    attrs: {
      "type": "textarea",
      "rows": 2,
      "placeholder": "备注"
    },
    model: {
      value: _vm.createParmas.reamk,
      callback: function callback($$v) {
        _vm.$set(_vm.createParmas, "reamk", $$v);
      },
      expression: "createParmas.reamk"
    }
  })], 1) : _c('div', [_c('ul', [_c('li', {
    staticClass: "x3"
  }, [_c('div', [_vm._v("可认购数量")]), _c('div', [_vm._v(_vm._s(_vm.params.appointmentCount) + "套")])]), _c('li', {
    staticClass: "x3"
  }, [_c('div', [_vm._v("应收诚意金")]), _c('div', [_vm._v("¥ " + _vm._s(_vm.params.appointmentAmount))])]), _c('li', {
    staticClass: "x3"
  }, [_c('div', [_vm._v("累计已收款")]), _c('div', [_vm._v("¥ " + _vm._s(_vm.params.payAmount))])]), _c('li', {
    staticClass: "x3 border-bottom"
  }, [_c('div', [_vm._v("已转认购金")]), _c('div', [_vm._v("¥ " + _vm._s(_vm.params.orderAmount))])]), _c('li', {
    staticClass: "x3"
  }, [_c('div', [_vm._v("可退款金额")]), _c('div', {
    staticClass: "red"
  }, [_vm._v(" ¥ " + _vm._s(_vm.params.onlineRefundAmount + _vm.params.offlineRefundAmount) + " ")])])]), _c('div', {
    staticStyle: {
      "font-size": "14px",
      "color": "#333"
    }
  }, [_vm._v(" 其中，“微信支付”退款 ¥" + _vm._s(_vm.params.onlineRefundAmount) + "；“线下支付”退款 ¥" + _vm._s(_vm.params.offlineRefundAmount) + " ")]), _c('div', {
    staticStyle: {
      "font-size": "14px",
      "color": "#999"
    }
  }, [_vm._v(" “微信支付”部分发起退款后，将原路退回到对应微信账户，实时到账，不支持撤回； ")])])]), _c('span', {
    staticClass: "dialog-footer",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    attrs: {
      "size": "small"
    },
    on: {
      "click": _vm.handleClose
    }
  }, [_vm._v("取 消")]), _c('el-button', {
    attrs: {
      "type": "primary",
      "size": "small"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v(" " + _vm._s(_vm.type ? "确定收款" : "确定退款"))])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }