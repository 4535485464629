var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', {
    attrs: {
      "title": _vm.createParmas.id ? '编辑预约登记' : '创建预约登记',
      "visible": _vm.visible,
      "width": "500px",
      "before-close": _vm.handleClose,
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c('el-form', {
    ref: "form",
    staticClass: "form",
    attrs: {
      "model": _vm.createParmas,
      "label-width": "100px",
      "size": "small"
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "所属活动:",
      "rules": [_vm.rules.required()],
      "prop": "openQuotationActivityId"
    }
  }, [_c('el-select', {
    staticClass: "activity-select",
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "placeholder": "请选择"
    },
    model: {
      value: _vm.createParmas.openQuotationActivityId,
      callback: function callback($$v) {
        _vm.$set(_vm.createParmas, "openQuotationActivityId", $$v);
      },
      expression: "createParmas.openQuotationActivityId"
    }
  }, _vm._l(_vm.activityList, function (item, i) {
    return _c('el-option', {
      key: i,
      attrs: {
        "label": item.activityName,
        "value": item.id
      }
    });
  }), 1)], 1), _c('div', {
    staticClass: "text"
  }, [_vm._v(" 认购客户信息"), _c('br'), _c('span', [_vm._v("以下字段信息是客户登录在线认购平台关键信息，请准确填写")])]), _c('el-form-item', {
    attrs: {
      "label": "客户姓名:",
      "rules": [_vm.rules.required()],
      "prop": "customerName"
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入"
    },
    model: {
      value: _vm.createParmas.customerName,
      callback: function callback($$v) {
        _vm.$set(_vm.createParmas, "customerName", $$v);
      },
      expression: "createParmas.customerName"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "身份证号:",
      "rules": [_vm.rules.required(), _vm.rules.idNum],
      "prop": "customerIdcard"
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入"
    },
    model: {
      value: _vm.createParmas.customerIdcard,
      callback: function callback($$v) {
        _vm.$set(_vm.createParmas, "customerIdcard", $$v);
      },
      expression: "createParmas.customerIdcard"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "手机号码:",
      "rules": [_vm.rules.required(), _vm.rules.tel],
      "prop": "customerPhone"
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入"
    },
    model: {
      value: _vm.createParmas.customerPhone,
      callback: function callback($$v) {
        _vm.$set(_vm.createParmas, "customerPhone", $$v);
      },
      expression: "createParmas.customerPhone"
    }
  })], 1), _c('div', {
    staticClass: "text"
  }, [_vm._v(" 诚意金"), _c('br'), _c('span', [_vm._v("最大可认购数、诚意金金额均有当前活动定义")])]), _c('el-form-item', {
    attrs: {
      "label": "认购数量:"
    }
  }, [_c('el-input-number', {
    attrs: {
      "min": 1,
      "max": _vm.createParmas.openQuotationActivityId && _vm.activityList.filter(function (op) {
        return _vm.createParmas.openQuotationActivityId == op.id;
      })[0].numberLimit || 1,
      "label": "描述文字"
    },
    model: {
      value: _vm.createParmas.appointmentCount,
      callback: function callback($$v) {
        _vm.$set(_vm.createParmas, "appointmentCount", $$v);
      },
      expression: "createParmas.appointmentCount"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "诚意金:"
    }
  }, [_c('div', {
    staticClass: "red"
  }, [_vm._v(" ¥ " + _vm._s(_vm.createParmas.openQuotationActivityId ? _vm.activityList.filter(function (op) {
    return op.id == _vm.createParmas.openQuotationActivityId;
  })[0].earnestMoney * _vm.createParmas.appointmentCount : 0) + " ")])])], 1), _c('span', {
    staticClass: "dialog-footer",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    attrs: {
      "size": "small"
    },
    on: {
      "click": _vm.handleClose
    }
  }, [_vm._v("取 消")]), _c('el-button', {
    attrs: {
      "type": "primary",
      "size": "small"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("确 定")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }