<template>
  <el-dialog
    :title="type ? '创建收款' : '创建退款'"
    :visible.sync="visible"
    width="500px"
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <div class="content">
      <div class="x3 aic border-bottom">
        <div class="title  ">
          诚意金<br /><span>最大可认购数、诚意金金额均有当前活动定义</span>
        </div>
        <img src="@/assets/shou.png" alt="" v-if="type" width="35px" />
        <img src="@/assets/tui.png" alt="" v-else width="35px" />
      </div>
      <div v-if="type">
        <ul>
          <li class="x3">
            <div>可认购数量</div>
            <div>{{ params.appointmentCount }}套</div>
          </li>
          <li class="x3 border-bottom ">
            <div>应收诚意金</div>
            <div>¥ {{ params.appointmentAmount }}</div>
          </li>
          <li class="x3">
            <div>累计已收款</div>
            <div>¥ {{ params.payAmount }}</div>
          </li>
          <li class="x3 border-bottom ">
            <div>本次应收款</div>
            <div class="red">¥ {{ params.appointmentAmount }}</div>
          </li>
        </ul>

        <div class="tigs">确认后不可修改，你确定要执行收款确认吗？</div>
        <el-input
          type="textarea"
          :rows="2"
          placeholder="备注"
          v-model="createParmas.reamk"
        >
        </el-input>
      </div>
      <div v-else>
        <ul>
          <li class="x3">
            <div>可认购数量</div>
            <div>{{ params.appointmentCount }}套</div>
          </li>
          <li class="x3 ">
            <div>应收诚意金</div>
            <div>¥ {{ params.appointmentAmount }}</div>
          </li>
          <li class="x3">
            <div>累计已收款</div>
            <div>¥ {{ params.payAmount }}</div>
          </li>
          <li class="x3 border-bottom ">
            <div>已转认购金</div>
            <div>¥ {{ params.orderAmount }}</div>
          </li>
          <li class="x3  ">
            <div>可退款金额</div>
            <div class="red">
              ¥ {{ params.onlineRefundAmount + params.offlineRefundAmount }}
            </div>
          </li>
        </ul>

        <div style="font-size:14px;color: #333;">
          其中，“微信支付”退款 ¥{{ params.onlineRefundAmount }}；“线下支付”退款
          ¥{{ params.offlineRefundAmount }}
        </div>
        <div style="font-size:14px;color: #999;">
          “微信支付”部分发起退款后，将原路退回到对应微信账户，实时到账，不支持撤回；
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose" size="small">取 消</el-button>
      <el-button type="primary" @click="save" size="small">
        {{ type ? "确定收款" : "确定退款" }}</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import { appointmentPay, appointmentRefund } from "@/api/appointment";
export default {
  model: {
    prop: "visible",
    event: "input"
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    params: {
      type: Object,
      default: () => ({})
    },
    type: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      createParmas: {
        reamk: ""
        // id:0,
      }
    };
  },
  watch: {
    visible(val) {
      if (val && this.type) {
        this.createParmas.reamk = "";
      }
    }
  },
  methods: {
    async save() {
      if (this.type) {
        let data = await appointmentPay(this.params.id, this.createParmas);
        data && this.$showSuccess("收款成功");
      } else {
        let data = await appointmentRefund(this.params.id, this.createParmas);
        data && this.$showSuccess("退款成功");
      }

      this.$emit("input");
    },
    handleClose() {
      this.$emit("input", false);
    }
  }
};
</script>
<style lang="scss" scoped>
.content {
  .title {
    font-size: 16px;
    font-weight: bold;
    color: #333;
    padding: 10px 0 0;
    span {
      display: inline-block;
      margin-top: 10px;
      font-size: 13px;
      color: #999;
      font-weight: initial;
    }
  }
  .tigs {
    font-size: 16px;
    padding-bottom: 15px;
    color: #333;
  }
  ul > li {
    margin: 10px 0;
    .red {
      font-size: 20px;
      font-weight: bold;
      color: #ff0000;
    }
  }
  .border-bottom {
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
  }
}
</style>
