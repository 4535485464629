<template>
  <div class="item-body">
    <div class="x3 header">
      <div>
        活动：
        <el-select
          v-model="checkActivity"
          placeholder="请选择"
          class="activity-select"
          @change="appointmentList"
        >
          <el-option
            v-for="(item, i) in activityList"
            :key="i"
            :label="item.activityName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="section">
      <div class="x aic tigs">
        <i class="el-icon-info"></i>
        <div class="text">
          只有预约登记并缴纳诚意金后，才有资格参与线上选房或选车位；通过“锁定”可以限制该客户选房或选车位
        </div>
      </div>
      <div class="x3 ">
        <el-row :gutter="20" class="search-style">
          <el-col :span="6">
            <el-select
              v-model="listParams.status"
              @change="appointmentList"
              @clear="appointmentList"
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="(item, index) in statusList"
                :key="index"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="6">
            <el-date-picker
              style="width:100%"
              clearable
              @change="datePickerChange"
              v-model="dataValue"
              type="daterange"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </el-col>
          <el-col :span="8">
            <el-input
              placeholder="订单编号或认购人姓名、手机号"
              v-model="listParams.keyWord"
              clearable
              class="f-search-bar"
            >
              <el-button slot="append" @click="appointmentList">搜索</el-button>
            </el-input>
          </el-col>
        </el-row>
        <div @click="careateOrEdit('')" class="careate-edit">
          <i class="icon iconfont icon-newAdd"></i><span>创建预约登记</span>
        </div>
      </div>
      <el-table :data="tableList" border style="width: 100%">
        <el-table-column prop="date" label="认购客户" width="180">
          <template slot-scope="scope">
            <div :class="{ 'lock-style': scope.row.locked }">
              <div>{{ scope.row.customerName }}</div>
              <div style="color:#999">{{ scope.row.customerPhone }}</div>
              <div style="color:#999">{{ scope.row.customerIdcard }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="诚意金" width="180">
          <template slot-scope="scope">
            <div :class="{ 'lock-style': scope.row.locked }">
              <div>¥{{ scope.row.appointmentAmount }}</div>
              <div
                v-if="
                  scope.row.logItems.length === 1 &&
                    !parseInt(scope.row.logItems[0].logType)
                "
                :style="{ color: earnestMoney[0] }"
              >
                未支付
              </div>

              <!-- <div v-else-if="scope.row.orderItems.length">
                <div v-for="(item, index) in scope.row.orderItems" :key="index">
                  <span :style="{ color: earnestMoney['15'] }">
                    转认购，¥ {{ item.price }}</span
                  >
                </div>
              </div> -->
              <div
                v-else
                v-for="(item, index) in scope.row.logItems"
                :key="index"
              >
                <span :style="{ color: earnestMoney[item.logType] }">{{
                  item.logDesc
                }}</span>
                <el-popover placement="right" width="315" trigger="click">
                  <div class="popover-price">
                    <div v-if="scope.row.refundItems.length">
                      <div class="title">
                        退款记录
                      </div>
                      <div
                        v-for="item in scope.row.refundItems"
                        :key="item.orderId"
                      >
                        <div>
                          <div
                            class="x"
                            v-if="item.payType == 1"
                            style="color:#41B035"
                          >
                            <i
                              class="icon iconfont icon-weixinzhifu"
                              style="margin-right:10px"
                            ></i>
                            <div>微信支付</div>
                          </div>
                          <div class="x" v-else style="color:#28A0F6">
                            <i
                              class="icon iconfont icon-xianxiazhifu_1"
                              style="margin-right:10px"
                            ></i>
                            <div>线下</div>
                          </div>
                        </div>
                        <ul>
                          <li>
                            <span class="name">退款状态</span>
                            <span style="color:red"
                              >{{
                                item.tradeType == 2 ? "已退款" : "已收款"
                              }}¥{{ item.amount }}</span
                            >
                          </li>
                          <li>
                            <span class="name">退款时间</span> {{ item.date }}
                          </li>
                          <li>
                            <span class="name">交易单号</span>
                            {{ item.tradeNo }}
                          </li>
                          <li>
                            <span class="name">退款发起人</span>
                            {{ item.userName }}
                          </li>
                          <li>
                            <span class="name">退款备注</span> {{ item.remark }}
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div v-if="scope.row.payItems.length">
                      <div class="title">
                        收款记录
                      </div>
                      <div
                        v-for="item in scope.row.payItems"
                        :key="item.orderId"
                      >
                        <div>
                          <div
                            class="x"
                            v-if="item.payType == 1"
                            style="color:#41B035"
                          >
                            <i
                              class="icon iconfont icon-weixinzhifu"
                              style="margin-right:10px"
                            ></i>
                            <div>微信支付</div>
                          </div>
                          <div class="x" v-else style="color:#28A0F6">
                            <i
                              class="icon iconfont icon-xianxiazhifu_1"
                              style="margin-right:10px"
                            ></i>
                            <div>线下</div>
                          </div>
                        </div>
                        <ul>
                          <li>
                            <span class="name">收款状态</span>
                            <span style="color:red"
                              >{{
                                item.tradeType == 2 ? "已退款" : "已收款"
                              }}¥{{ item.amount }}</span
                            >
                          </li>
                          <li>
                            <span class="name">收款时间</span> {{ item.date }}
                          </li>
                          <li>
                            <span class="name">交易单号</span>
                            {{ item.tradeNo }}
                          </li>
                          <li>
                            <span class="name">收款发起人</span>
                            {{ item.userName }}
                          </li>
                          <li>
                            <span class="name">收款备注</span> {{ item.remark }}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <i
                    v-if="scope.row.logItems.length - 1 == index"
                    slot="reference"
                    class="el-icon-info"
                  ></i>
                </el-popover>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="appointmentCount" label="可认购数">
          <template slot-scope="scope">
            <div :class="{ 'lock-style': scope.row.locked }">
              {{ scope.row.appointmentCount }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="inDate" label="申请时间">
          <template slot-scope="scope">
            <div :class="{ 'lock-style': scope.row.locked }">
              {{ scope.row.inDate }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="认购情况">
          <template slot-scope="scope">
            <div :class="{ 'lock-style': scope.row.locked }">
              <div v-if="scope.row.orderItems.length">
                <el-button
                  type="text"
                  v-for="(item, index) in scope.row.orderItems"
                  :key="index"
                  >认购单号{{ item.orderNo }}</el-button
                >
              </div>
              <span v-else>-</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="操作">
          <template slot-scope="scope">
            <el-button
              type="text"
              v-if="
                !scope.row.orderItems.length &&
                  scope.row.logItems.every(op => !parseInt(op.logType)) &&
                  scope.row.appointmentAmount
              "
              @click="createEditPay(scope.row, 1)"
              >收款</el-button
            >
            <span>
              <el-button
                type="text"
                v-if="
                  scope.row.refundAmount < 0 ||
                    scope.row.logItems.some(op =>
                      [1, 10, 15].includes(parseInt(op.logType))
                    )
                "
                @click="createEditPay(scope.row, 0)"
                >退款</el-button
              >
            </span>
            <el-button
              type="text"
              v-if="
                scope.row.logItems.some(
                  op => ![15, 20].includes(parseInt(op.logType))
                ) || !scope.row.logItems.length
              "
              @click="careateOrEdit(scope.row)"
              >编辑</el-button
            >
            <el-popconfirm
              v-if="
                !scope.row.locked &&
                  (scope.row.logItems.some(op =>
                    [1, 10].includes(parseInt(op.logType))
                  ) ||
                    !scope.row.logItems.length)
              "
              confirm-button-text="好的"
              cancel-button-text="不用了"
              icon="el-icon-info"
              icon-color="red"
              title="确定锁定吗？"
              @confirm="appointmentLock(scope.row, 1)"
            >
              <el-button type="text" slot="reference">锁定</el-button>
            </el-popconfirm>
            <el-popconfirm
              v-if="
                scope.row.locked &&
                  (scope.row.logItems.some(op =>
                    [1, 10].includes(parseInt(op.logType))
                  ) ||
                    !scope.row.logItems.length)
              "
              confirm-button-text="好的"
              cancel-button-text="不用了"
              icon="el-icon-info"
              icon-color="red"
              title="确定恢复吗？"
              @onConfirm="appointmentLock(scope.row, 0)"
            >
              <el-button type="text" slot="reference">恢复</el-button>
            </el-popconfirm>
            <el-popconfirm
              v-if="
                scope.row.logItems.some(op =>
                  [0, 20].includes(parseInt(op.logType))
                )
              "
              confirm-button-text="好的"
              cancel-button-text="不用了"
              icon="el-icon-info"
              icon-color="red"
              title="确定删除吗？"
              @onConfirm="appointmentDelete(scope.row)"
            >
              <el-button type="text" slot="reference">删除</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[5, 10, 15, 20]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableTotal"
      >
      </el-pagination>
    </div>
    <createAndEdit
      v-model="createVisible"
      :params="createItem"
      @input="appointmentList"
    ></createAndEdit>
    <createCollection
      v-model="createPayVisible"
      :params="createPayItem"
      :type="createPayType"
      @input="appointmentList"
    ></createCollection>
  </div>
</template>
<script>
import { getActivityList } from "@/api/marketActivity";
import {
  appointmentList,
  appointmentPay,
  appointmentUnlock,
  appointmentLock,
  appointmentDelete
} from "@/api/appointment";
import createAndEdit from "./components/createAndEdit";
import createCollection from "./components/createCollection";
export default {
  components: { createAndEdit, createCollection },
  data() {
    return {
      activityList: [],
      checkActivity: "",
      dataValue: "",
      createVisible: false,
      createPayVisible: false,
      createPayItem: {},
      createItem: {},
      createPayType: 1,
      currentPage: 1,
      statusList: [
        { name: "待支付", id: 1 },
        { name: "已支付", id: 5 },
        { name: "已认购", id: 10 },
        { name: "退款", id: 20 }
      ],
      // 0未支付 1为微信支付 10为线下支付 15 转认购 20 退款
      earnestMoney: {
        0: "#FF0000",
        1: "#008000",
        10: "#008000",
        15: "#FF66CC",
        16: "#FF66CC",
        20: "#FF0000"
      },
      tableList: [],
      tableTotal: 0,
      listParams: {
        pageNum: 1,
        pageSize: 10,
        status: "",
        openQuotationActivityId: "",
        startDate: "",
        endDate: "",
        keyWord: ""
      }
    };
  },
  computed: {
    estateId() {
      return this.$store.state.estateInfo.estateId;
    }
  },
  created() {
    this.getActivityList();
  },
  methods: {
    datePickerChange() {
      let a = "",
        b = "";
      if (this.dataValue && this.dataValue.length) {
        a = this.dataValue[0];
        b = this.dataValue[1];
      }
      this.listParams.startDate = a;
      this.listParams.endDate = b;
      this.appointmentList();
    },
    handleSizeChange(val) {
      this.listParams.pageSize = val;
      this.appointmentList();
    },
    handleCurrentChange(val) {
      this.listParams.pageNum = val;
      this.appointmentList();
    },
    careateOrEdit(item) {
      let arr = [
        "appointmentCount",
        "customerIdcard",
        "customerName",
        "customerPhone",
        "openQuotationActivityId",
        "id"
      ];
      let obj = {
        appointmentCount: 0,
        customerIdcard: "",
        customerName: "",
        customerPhone: "",
        openQuotationActivityId: ""
      };
      this.createVisible = true;
      item &&
        arr.map(op => {
          obj[op] = item[op];
        });

      this.createItem = obj;
    },
    async appointmentPay() {
      await appointmentPay();
    },
    createEditPay(item, type) {
      this.createPayVisible = true;
      this.createPayType = type;
      this.createPayItem = item;
    },
    /**
     * 锁定  恢复
     */
    async appointmentLock({ id }, type) {
      if (type) {
        let data = await appointmentLock(id);
        data && this.$showSuccess("锁定成功");
      } else {
        let data = await appointmentUnlock(id);
        data && this.$showSuccess("恢复成功");
      }

      this.appointmentList();
    },
    async appointmentDelete({ id }) {
      let data = await appointmentDelete(id);
      if (data) {
        this.$showSuccess("删除成功");
        this.appointmentList();
      }
    },
    /**
     * 获取活动
     */
    async getActivityList() {
      this.activityList = (await getActivityList(this.estateId)) || [];

      if (this.activityList.length) {
        this.checkActivity = this.activityList[0].id;

        this.listParams.openQuotationActivityId = this.checkActivity;
        this.appointmentList();
      }
    },
    /**
     * 获取预约列表
     */
    async appointmentList(flag = true) {
      if (!flag) return;
      this.listParams.openQuotationActivityId = this.checkActivity;
      let data = await appointmentList(this.listParams);

      this.tableList = data.list;
      this.tableTotal = data.total;
    }
  }
};
</script>
<style lang="scss" scoped>
::v-deep {
  .el-popover__reference-wrapper {
    button.el-button.el-button--text {
      margin-right: 10px;
    }
  }
}
.el-table {
  .el-button {
    margin-left: 10px;
  }
  i {
    color: #999;
  }
  .lock-style {
    color: #999;
    span {
      color: #999 !important;
    }
  }
}
.popover-price {
  max-height: 600px;
  overflow-y: auto;
  & > div {
    border-bottom: 1px solid #eee;
    margin-bottom: 15px;
    padding-bottom: 15px;
    .title {
      font-size: 16px;
      font-weight: bold;
      color: #000;
      padding-bottom: 10px;
    }

    &:last-child {
      border-bottom: none;
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }

  ul li {
    margin: 5px 0;
    color: #333;
    .name {
      color: #999;
      margin-right: 5px;
    }
  }
}
.item-body {
  padding: 20px 30px;
  background: #ffffff;
  .search-style {
    margin-bottom: 20px;
  }

  .header {
    border-bottom: 1px solid #eeeeee;
    margin-bottom: 20px;
    .activity-select {
      width: 300px;
      ::v-deep {
        .el-input {
          .el-input__inner {
            border: none;
            font-size: 16px;
            color: #3470fc;
          }
        }
      }
    }
  }
  .section {
    .careate-edit {
      width: 140px;
      height: 40px;
      border-radius: 5px;
      text-align: center;
      line-height: 40px;
      background: #3470fc;
      color: #ffffff;
      cursor: pointer;
      i {
        margin-right: 10px;
        font-weight: bold;
        font-size: 16px;
      }
    }
    .tigs {
      font-size: 14px;
      color: #666;
      margin: 0 0 25px;
      .text {
        font-size: 14px;
      }
    }
  }
}
</style>
