<template>
  <el-dialog
    :title="createParmas.id ? '编辑预约登记' : '创建预约登记'"
    :visible.sync="visible"
    width="500px"
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <el-form
      class="form"
      :model="createParmas"
      ref="form"
      label-width="100px"
      size="small"
    >
      <el-form-item
        label="所属活动:"
        :rules="[rules.required()]"
        prop="openQuotationActivityId"
      >
        <el-select
          v-model="createParmas.openQuotationActivityId"
          placeholder="请选择"
          style="width:100%"
          class="activity-select"
        >
          <el-option
            v-for="(item, i) in activityList"
            :key="i"
            :label="item.activityName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <div class="text">
        认购客户信息<br />
        <span>以下字段信息是客户登录在线认购平台关键信息，请准确填写</span>
      </div>
      <el-form-item
        label="客户姓名:"
        :rules="[rules.required()]"
        prop="customerName"
      >
        <el-input
          v-model="createParmas.customerName"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="身份证号:"
        :rules="[rules.required(), rules.idNum]"
        prop="customerIdcard"
      >
        <el-input
          v-model="createParmas.customerIdcard"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="手机号码:"
        :rules="[rules.required(), rules.tel]"
        prop="customerPhone"
      >
        <el-input
          v-model="createParmas.customerPhone"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
      <div class="text">
        诚意金<br />
        <span>最大可认购数、诚意金金额均有当前活动定义</span>
      </div>
      <el-form-item label="认购数量:">
        <el-input-number
          v-model="createParmas.appointmentCount"
          :min="1"
          :max="
            (createParmas.openQuotationActivityId &&
              activityList.filter(
                op => createParmas.openQuotationActivityId == op.id
              )[0].numberLimit) ||
              1
          "
          label="描述文字"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="诚意金:">
        <div class="red">
          ¥
          {{
            createParmas.openQuotationActivityId
              ? activityList.filter(
                  op => op.id == createParmas.openQuotationActivityId
                )[0].earnestMoney * createParmas.appointmentCount
              : 0
          }}
        </div>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose" size="small">取 消</el-button>
      <el-button type="primary" @click="save" size="small">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { getActivityList } from "@/api/marketActivity";
import { createAppointment } from "@/api/appointment";
import rules from "@/utils/rules";
let par = () => ({
  appointmentCount: 0,
  customerIdcard: "",
  customerName: "",
  customerPhone: "",
  openQuotationActivityId: ""
});
export default {
  model: {
    prop: "visible",
    event: "input"
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    params: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    estateId() {
      return this.$store.state.estateInfo.estateId;
    }
  },
  data() {
    return {
      rules,
      checkActivity: "",
      activityList: [],
      createParmas: par()
    };
  },
  watch: {
    params(val) {
      let isKey = Object.keys(val).length;
      this.createParmas = isKey ? val : par();
      !val.id &&
        this.$nextTick(() => {
          this.$refs.form.resetFields();
        });
    }
  },
  created() {
    this.getActivityList();
  },
  methods: {
    async save() {
      //提交表单
      this.$refs.form.validate(async valid => {
        if (!valid) return;

        let data = await createAppointment(this.createParmas);
        if (data) {
          this.$showSuccess(this.createParmas.id ? "编辑成功" : "创建成功");
          this.$emit("input");
        }
      });
    },
    async getActivityList() {
      this.activityList = (await getActivityList(this.estateId)) || [];
    },
    handleClose() {
      this.$emit("input", false);
    }
  }
};
</script>
<style lang="scss" scoped>
.text {
  padding-left: 25px;
  font-size: 16px;
  font-weight: bold;
  color: #333;
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 10px;
  margin-bottom: 10px;
  span {
    font-size: 13px;
    color: #999;
    font-weight: initial;
    display: inline-block;
    margin-top: 10px;
  }
}
.red {
  font-size: 18px;
  font-weight: bold;
  color: #ff0000;
  text-align: right;
}
</style>
