import request from "@/utils/request";

/**
 * 预约列表
 */
export function appointmentList(data) {
  return request({
    url: `/api/v1/platform/open/auotation/activity/appointment/manage/list`,
    method: "get",
    data
  });
}

/**
 * 创建预约
 */
export function createAppointment(data) {
  return request({
    url: `/api/v1/platform/open/auotation/activity/appointment/manage/create`,
    method: "post",
    data
  });
}

/**
 * 收款
 */
export function appointmentPay(id, data) {
  return request({
    url: `/api/v1/platform/open/auotation/activity/appointment/manage/${id}/pay`,
    method: "post",
    data
  });
}

/**
 * 退款
 */
export function appointmentRefund(id, data) {
  return request({
    url: `/api/v1/platform/open/auotation/activity/appointment/manage/${id}/refund`,
    method: "post",
    data
  });
}

/**
 * 锁定
 */
export function appointmentLock(id, data) {
  return request({
    url: `/api/v1/platform/open/auotation/activity/appointment/manage/${id}/lock`,
    method: "post",
    data
  });
}

/**
 * 恢复
 */
export function appointmentUnlock(id, data) {
  return request({
    url: `/api/v1/platform/open/auotation/activity/appointment/manage/${id}/unlock`,
    method: "post",
    data
  });
}

/**
 * 删除
 */
export function appointmentDelete(id, data) {
  return request({
    url: `/api/v1/platform/open/auotation/activity/appointment/manage/${id}/delete`,
    method: "post",
    data
  });
}
